import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy1A(props) {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  
  useEffect(() => {
    const Copy1AaElement = document.getElementById('Copy1AA');
    const calculatedMarginA = (window.innerHeight - Copy1AaElement.clientHeight) / 2;
    setmarginsA(window.innerHeight / 4
      // calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
   
  }, []);

  return (
    <Container>
      <div id="Copy1AA" style={{ margin: `${window.innerHeight / 5}px auto ${window.innerHeight /3}px` }}>
        <Typography className={classes.textMonarcha} sx={{ color: '#ffbf12' }} variant="h3">
          Content creators <br />
          <span style={{ color: '#fff' }}> lacking</span> future financial security
          <br />
          <br />
          —PassionStocks was built for you.
        </Typography>
      </div>
    </Container>
  );
}
