import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Agency__Copy5() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);
  const [marginsD, setmarginsD] = useState(0);

  useEffect(() => {
    const Copy5AElement = document.getElementById('Copy5A');
    const calculatedMarginA = (window.innerHeight - Copy5AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );

    const Copy5BElement = document.getElementById('Copy5B');
    const calculatedMarginB = (window.innerHeight - Copy5BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );

    const Copy5CElement = document.getElementById('Copy5C');
    const calculatedMarginC = (window.innerHeight - Copy5CElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );

    const Copy5DElement = document.getElementById('Copy5D');
    const calculatedMarginD = (window.innerHeight - Copy5DElement.clientHeight) / 2;
    setmarginsD(
      calculatedMarginD > window.innerHeight / 4 ? calculatedMarginD : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy5A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Every creator agency is a{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 6 }}
          variant="h2"
        >
          Personal brand agency
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Prepare your personal brand <br />
          agency to be listed on the{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
        >
          Stock exchange
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 6 }}
        >
          Right from day one !
        </Typography>
      </div>

      <div id="Copy5B" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h2"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 4 }}
        >
          Help creators
          <br />
          Make a living
          <br />
          Create wealth
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 6 }}
        >
          By selling <br />
          Only shoutouts
        </Typography>
      </div>

      <div id="Copy5C" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Invite creators To join Your
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          Personal brand
          <br />
          Agency
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          to help them build <br />
          $1B personal brands & beyond!{' '}
        </Typography>{' '}
      </div>

      <div id="Copy5D" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 4 }}
        >
          Remind your creators
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 4 }}
        >
          Today’s <br />
          popularity
          <p style={{ fontSize: '28px',lineHeight:'28px' }}>is</p>
          Tomorrow’s <br />
          Security
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Turn your personal brand Into <br />
          stocks today—secure tomorrow’s <br />
          financial future!
        </Typography>
      </div>
    </Container>
  );
}
