import { Box, Button, Container, Typography } from '@mui/material';
import PSLogoYellow from '../../../../images/ps-icon.png';
import { makeStyles } from '@mui/styles';
import { withWidth } from '@material-ui/core';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
const Creators__OpeningSlide = ({ width, ...props }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 9,
          width: 'min-content',
        }}
      >
        <img src={PSLogoYellow} alt="" style={{ width: '35%', display: 'block' }} />
        <Typography
          variant="h3"
          className={classes.textMonarcha}
          sx={{ textTransform: 'none !important', width: 'fit-content' }}
        >
          PassionStocks
        </Typography>
      </Box>
      <Container sx={{ marginBottom: width !== 'xl' ? 5 : 7 }}>
        <Typography
          variant="h4"
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.5 }}
        >
          Turn socials to stocks
        </Typography>
        <Typography
          variant="h5"
          className={classes.textMonarcha}
          sx={{ color: '#ffffff44', lineHeight: 1.2 }}
        >
          Your personal brand’s worth {width === 'xs' && <br />} is{" "}
          {width !== 'xs' && <br />}
          born & raised here
        </Typography>
      </Container>

      <JoinTheWaitlistButton />
    </Box>
  );
};
export default withWidth()(Creators__OpeningSlide);
