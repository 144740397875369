import React from 'react';
import { Alert, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import StarsEyesEmoji from '../../../../images/emoji-stars-eyes.png';
import Done from '../../../../images/confirm.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isMobileDevice from '../../../../utiles/isMobileDevice';
import axios from 'axios';
import store from '../../../../store/store';
import isValidateEmail from '../../../../helpers/isValidateEmail';

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectWaitlistSelect = (state) => state.waitlistSelect;

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function JoinTheWaitlistSlide() {
  const classes = useStyles();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  const waitlistSelect = useSelector(selectWaitlistSelect);

  const roles = ['creator agency', 'creator', 'investor', 'business', 'other'];
  const [selectedCheckbox, setSelectedCheckbox] = useState('Select');
  const [userEmail, setUserEmail] = useState('');
  const [isTsAndCsChecked, setisTsAndCsChecked] = useState(null);
  const [isPSContactChecked, setisPSContactChecked] = useState(null);

  const handleSelectChange = (event) => {
    store.dispatch({
      type: 'ui/waitlistSelect/add',
      payload: null,
    });
    setSelectedCheckbox(event.target.value);
  };
  const handleSubmit = () => {
    if (
      userEmail &&
      userEmail !== '' &&
      selectedCheckbox !== null &&
      selectedCheckbox !== '' &&
      selectedCheckbox !== 'Select' &&
      isTsAndCsChecked &&
      isPSContactChecked
    ) {
      var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + '/api/users/waiting-list',
        params: { email: userEmail, role: selectedCheckbox },
      };

      axios(config)
        .then(function (response) {
          setOpen('success');
          setMessage('Your request has been successfully received');
        })
        .catch(function (error) {
          if (error.response?.data?.message) {
            setOpen('info');
            setMessage(error.response.data.message);
          } else {
            setOpen('error');
            setMessage('There is a problem, please try again later');
          }
        });
      setTimeout(() => {
        setOpen(null);
      }, 6000);
    } else {
      if (userEmail === null) {
        setOpen('info');
        setMessage('Please enter a valid email address');
      } else {
        setOpen('info');
        setMessage('Please complete missing details');
      }
    }
  };

  // Snackbar display wehn submit waiting list form:
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // slide margin
  const [marginsA, setmarginsA] = useState(0);

  useEffect(() => {
    const copy1aElement = document.getElementById('Copy1A');
    const calculatedMarginA = (window.innerHeight - copy1aElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);
  return (
    <div
      className="HomeDesktop__SlideHeaderContainer HomeDesktop__Slide unnamed-character-style-1"
      id="LandingPage__JoinSlide"
      style={{
        margin: `${marginsA}px auto`,
        position: 'relative',
        top: '0',
        left: '0',
        transition: 'opacity 0.2s', // Add a transition effect
        zIndex: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div className="">
        <img
          className="HomeDesktop__Slide4__StarsEyesEmoji"
          src={StarsEyesEmoji}
          alt="Stars eyes emoji"
          style={{ width: isPhone ? '2.35em' : '3em' }}
        />
        <p
          className="LandingPage__Slide4__JoinTxt unnamed-character-style-1"
          style={{ fontSize: isPhone ? '1em' : '1.2em', margin: 0 }}
        >
          JOIN THE WAITLIST
        </p>
        <p
          className="headline"
          style={{
            margin: '0 0 1em',
            fontSize: isPhone ? '0.55em' : '0.65em',
            fontWeight: 'lighter',
          }}
        >
          Let’s personalize it!{isPhone ? <br /> : ''} Which option describes you best?{' '}
          <img
            src="https://em-content.zobj.net/source/apple/354/thinking-face_1f914.png"
            style={{
              width: '1.5em',
              position: 'relative',
              top: '0.4em',
              left: '0.2em',
            }}
          />
        </p>

        <select
          id="waitlistSelect"
          className="LandingPage__WishlistSelect"
          value={waitlistSelect ? waitlistSelect : selectedCheckbox}
          onChange={handleSelectChange}
        >
          <option value={''}>Select</option>
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>

        <div
          className="HomeDesktop__Slide4__EmailInputContainer"
          style={{ margin: isPhone ? '0.5em auto' : '1em auto' }}
        >
          {' '}
          <input
            className="HomeDesktop__Slide4__EmailInput"
            type={'email'}
            placeholder={'EMAIL'}
            value={userEmail}
            onChange={(e) => {
              if (e.target.value === '') {
                setUserEmail('');
              } else {
                setUserEmail(null);
              }
              if (isValidateEmail(e.target.value)) {
                setUserEmail(e.target.value);
              }
            }}
            style={{ margin: isPhone ? '0 auto 1.5em' : 'auto 0' }}
          />
          <img
            src={Done}
            alt="Submit"
            className="HomeDesktop__Slide4__DoneButton"
            onClick={handleSubmit}
          />
        </div>
        <div className="LandingPage__WishListCheckBoxContainer headline">
          <label className="container">
            <input
              type="checkbox"
              onChange={(e) => {
                setisTsAndCsChecked(e.target.checked);
              }}
            />{' '}
            I accept{' '}
            <Link
              to={'/terms-and-conditions'}
              state={{ from: 'wishlist' }}
              style={{ textDecoration: 'underline' }}
            >
              T’s & C’s
            </Link>
            <span className="checkmark"></span>
          </label>
          <label className="container" style={{ display: 'block' }}>
            <input
              type="checkbox"
              onChange={(e) => {
                setisPSContactChecked(e.target.checked);
              }}
            />{' '}
            I consent to PassionStocks contacting me via email
            <span className="checkmark"></span>
          </label>
        </div>

        {open && (
          <div style={{ width: '100%', position: 'fixed', bottom: '2em' }}>
            <Alert
              onClose={handleClose}
              // autoHideDuration={10000}
              severity={open}
              sx={{
                width: 'fit-content',
                alignSelf: 'center',
                margin: '0 auto',
                textTransform: 'none',
              }}
            >
              {message}
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
}
