import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import NumberedCirclesButtons from '../../../../../../../components/steps/NumberedCirclesButtons';
import { makeStyles } from '@mui/styles';
import JoinTheWaitlistButton from '../../../../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

export default function OneProduct__StoryShoutoutOI(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <FadeIn>
      <Box>
        <div>
          <Typography
            className={props.fontStyle}
            variant="h5"
            sx={{
              position: 'relative',
              display: 'block',
              textAlign: 'center',
              color: '#ffffff',
              lineHeight: 1.3,
              marginTop: 2,
            }}
          >
            On PassionStocks, <span style={{ color: '#ffbf12' }}>creators</span> can{' '}
            <span style={{ color: '#ffbf12' }}>
              sell only one product:
              <br />
              <br />
              shoutout{' '}
            </span>
            on instagram story.{' '}
          </Typography>
          <br />
          <br />

          <Typography
            className={props.fontStyle}
            variant="h5"
            sx={{
              position: 'relative',
              display: 'block',
              textAlign: 'center',
              color: '#ffbf12',
              lineHeight: 1.3,
              marginTop: 2,
              marginBottom: 3,
            }}
          >
            3 steps to sell shoutouts
          </Typography>
          <NumberedCirclesButtons count={3} checkedIndex={index} setIndex={setIndex} />
        </div>
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
          <div
            style={{
              margin: '30px auto 0',
              maxWidth: '90vw',
              height: ['430px', '670px', '840px'][index],
            }}
          >
            <div>
              <Typography
                className={props.fontStyle}
                variant="h5"
                sx={{ color: '#ffbf12', textAlign: 'center', marginBottom: 1 }}
              >
                1. Story Shoutout listing on the marketplace{' '}
              </Typography>
              <Typography
                className={classes.textOpenSans}
                variant="body"
                sx={{ display: 'block', color: '#ffffff77', textAlign: 'center' }}
              >
                join passionstocks, link your instagram, and let buyers find, purchase, and receive
                your shoutouts automatically. <br />
                <span style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                  below, you can see how story shoutouts appear on the marketplace.
                </span>
              </Typography>
              <img
                src={'/images/digital-card-details.svg'}
                alt="Thank You Mention Digital Card"
                style={{
                  display: 'block',
                  margin: '35px auto 0',
                  position: 'relative',
                  width: '680px',
                  zIndex: 6,
                }}
              />{' '}
              <Typography
                className={props.fontStyle}
                variant="subtitle1"
                sx={{
                  color: '#ffbf12',
                  textAlign: 'center',
                  lineHeight: 1.3,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                Buyers can resell your shoutouts if you allow them, <br />
                <span style={{ color: '#ffffff66' }}>
                  boosting your journey to a $1B personal brand valuation. Learn more{' '}
                </span>{' '}
              </Typography>
            </div>
          </div>
          <div
            style={{
              margin: '30px auto 0',
              maxWidth: '90vw',
              height: ['430px', '670px', '840px'][index],
            }}
          >
            <Typography
              className={props.fontStyle}
              variant="h5"
              sx={{ color: '#ffbf12', textAlign: 'center', marginBottom: 1 }}
            >
              2. place an order{' '}
            </Typography>
            <Typography
              className={classes.textOpenSans}
              variant="body"
              sx={{ display: 'block', color: '#ffffff77', textAlign: 'center' }}
            >
              based on your set preferences for timing and frequency,
              <br /> buyers can see the estimated delivery time for their shoutout on your instagram
              story,
              <br /> and complete their purchase instantly—no extra effort needed from you. <br />
              <span style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                below, you can see how the checkout process looks for buyers.{' '}
              </span>
            </Typography>
            <img
              src={'/images/review-purchase-details.svg'}
              alt="Thank You Mention Digital Card"
              style={{
                display: 'block',
                margin: '35px auto 0',
                position: 'relative',
                width: '520px',
                zIndex: 6,
              }}
            />{' '}
            <Typography
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                color: '#ffbf12',
                textAlign: 'center',
                lineHeight: 1.3,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Buyers can resell your shoutouts if you allow them, <br />
              <span style={{ color: '#ffffff66' }}>
                boosting your journey to a $1B personal brand valuation. Learn more{' '}
              </span>{' '}
            </Typography>
          </div>
          <div
            style={{
              margin: '30px auto 0',
              maxWidth: '90vw',
              height: ['430px', '670px', '840px'][index],
            }}
          >
            <Typography
              className={props.fontStyle}
              variant="h5"
              sx={{ color: '#ffbf12', textAlign: 'center', marginBottom: 1 }}
            >
              3. Shoutout Automatic Delivery{' '}
            </Typography>
            <Typography
              className={classes.textOpenSans}
              variant="body"
              sx={{ display: 'block', color: '#ffffff77', textAlign: 'center' }}
            >
              shoutouts are delivered automatically to buyers on your instagram story.
              <br /> buyers can also share the shoutout to their own story, making the process
              seamless and engaging.
              <br />
              <span style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                below, you can see how the story shoutout will appear on the creator’s instagram
                story.{' '}
              </span>
            </Typography>
            <img
              src={'/images/shoutout-thank-you.png'}
              alt="Thank You Mention Digital Card"
              style={{
                display: 'block',
                margin: '35px auto 0',
                position: 'relative',
                width: '380px',
                zIndex: 6,
              }}
            />{' '}
            <Typography
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                color: '#ffbf12',
                textAlign: 'center',
                lineHeight: 1.3,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Buyers can resell your shoutouts if you allow them, <br />
              <span style={{ color: '#ffffff66' }}>
                boosting your journey to a $1B personal brand valuation. Learn more{' '}
              </span>{' '}
            </Typography>
          </div>
        </SwipeableViews>
        <JoinTheWaitlistButton />
      </Box>
    </FadeIn>
  );
}
