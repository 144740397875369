import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Dialog, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwipeableViews from 'react-swipeable-views';
import PSStations from './slides/PSStations';
import PSAccountManager from './slides/PSAccountManager';
import { withWidth } from '@material-ui/core';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  root: {
    position: 'relative',
    width: '100%',
    // height: '100vh',
    overflow: 'hidden',
    padding: 0,
  },
  slide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: '#fff',
    fontSize: '30px',
  },
  slide1: {
    backgroundColor: '',
  },
  slide2: {
    backgroundColor: '',
  },
  slide3: {
    backgroundColor: '',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  navButtonLeft: {
    left: 0,
  },
  navButtonRight: {
    right: 0,
  },
});

const WelcomeSlide = ({ width, ...props }) => {
  const classes = useStyles();

  // use for slides height elements:
  const [height, setheight] = useState(0);

  const [index, setIndex] = useState(0);

  // const handleChangeIndex = (index) => {
  //   setIndex(index);
  // };

  return (
    <Box className={classes.root} sx={{ padding: 0 }}>
      {/* <SwipeableViews
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <div> */}
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          marginBottom: { xs: 1, sm: 0 },
          color: '#ffbf12',
        }}
      >
        Personal Brand startup{width === 'xs' && <br />} Journey on PassionStocks
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          // fontSize: { xs: '14px', sm: '20px' },
          color: '#ffffff77',
          marginBottom: 3,
        }}
      >
        PassionStocks lets content creators {width === 'xs' && <br />}launch their personal brand as
        a startup company.
      </Typography>
      <PSStations
        isPhone={props.isPhone}
        className={`${classes.slide}`}
        setIndex={setIndex}
        setheight={setheight}
      />{' '}
      <Box sx={{ marginTop: [18,0,5,0][index] }}>
        <JoinTheWaitlistButton />
      </Box>
    </Box>
  );
};

export default withWidth()(WelcomeSlide);
