import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import NumberedCirclesButtons from '../../../../../../../components/steps/NumberedCirclesButtons';
import { makeStyles } from '@mui/styles';
import JoinTheWaitlistButton from '../../../../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

export default function OneProduct__StoryShoutoutOI(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <Box>
      <div>
        <Typography
          className={props.fontStyle}
          sx={{
            position: 'relative',
            display: 'block',
            textAlign: 'center',
            color: '#ffffff',
            lineHeight: 1.3,
            marginTop: 0,
            fontSize: '12px',
          }}
        >
          On PassionStocks,
          <br /> <span style={{ color: '#ffbf12' }}>creators</span> can{' '}
          <span style={{ color: '#ffbf12' }}>
            sell only
            <br /> one product:
            <br />
            <br />
            shoutout{' '}
          </span>
          on instagram story.{' '}
        </Typography>

        <br />

        <Typography
          className={props.fontStyle}
          sx={{
            position: 'relative',
            display: 'block',
            textAlign: 'center',
            color: '#ffbf12',
            lineHeight: 1.3,
            marginTop: 0,
            marginBottom: 1.5,
            fontSize: '12px',
          }}
        >
          3 steps to sell shoutouts
        </Typography>
        <NumberedCirclesButtons
          variant={'small'}
          count={3}
          checkedIndex={index}
          setIndex={setIndex}
        />
      </div>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        <div
          style={{
            margin: '10px auto 0',
          }}
        >
          <div>
            <Typography
              className={props.fontStyle}
              sx={{ color: '#ffbf12', fontSize: '12px', textAlign: 'center', marginBottom: 1 }}
            >
              1. Story Shoutout listing
              <br /> on the marketplace{' '}
            </Typography>
            <Typography
              className={classes.textOpenSans}
              variant="body"
              sx={{ display: 'block', fontSize: '11px', color: '#ffffff77', textAlign: 'center' }}
            >
              join passionstocks, link your instagram,
              <br /> and let buyers find, purchase,
              <br /> and receive your shoutouts automatically. <br />
              <span
                style={{
                  fontSize: '10px',
                  lineHeight: '11px',
                  paddingTop: '14px',
                  fontWeight: 'bold',
                }}
              >
                below, you can see how story shoutouts
                <br /> appear on the marketplace.
              </span>
            </Typography>
            <img
              src={'/images/digital-card-details.svg'}
              alt="Thank You Mention Digital Card"
              style={{
                display: 'block',
                margin: '20px auto 0',
                position: 'relative',
                width: '230px',
                zIndex: 6,
              }}
              
            />{' '}
            <Typography
              className={props.fontStyle}
              sx={{
                color: '#ffbf12',
                textAlign: 'center',
                lineHeight: 1.3,
                fontSize: '12px',
                marginTop: 4,
                marginBottom: 0,
              }}
            >
              Buyers can resell your
              <br /> shoutouts if you allow them, <br />
              <span style={{ color: '#ffffff66' }}>
                boosting your journey to
                <br /> a $1B personal brand valuation.
                <br /> Learn more{' '}
              </span>{' '}
            </Typography>
          </div>
        </div>
        <div
          style={{
            margin: '10px auto 0',
          }}
        >
          <Typography
            className={props.fontStyle}
            sx={{ color: '#ffbf12', textAlign: 'center', fontSize: '12px', marginBottom: 1 }}
          >
            2. place an order{' '}
          </Typography>
          <Typography
            className={classes.textOpenSans}
            sx={{ display: 'block', fontSize: '11px', color: '#ffffff77', textAlign: 'center' }}
          >
            based on your set preferences for timing
            <br /> and frequency, buyers can see
            <br /> the estimated delivery time for their shoutout
            <br /> on your instagram story, and complete
            <br /> their purchase instantly—no extra effort
            <br /> needed from you. <br />
            <span
              style={{
                fontSize: '10px',
                lineHeight: '11px',
                paddingTop: '14px',
                fontWeight: 'bold',
              }}
            >
              below, you can see how
              <br /> the checkout process looks for buyers.{' '}
            </span>
          </Typography>
          <img
            src={'/images/review-purchase-details.svg'}
            alt="Thank You Mention Digital Card"
            style={{
              display: 'block',
              margin: '8px auto 0',
              position: 'relative',
              width: '230px',
              zIndex: 6,
            }}
          />{' '}
          <Typography
            className={props.fontStyle}
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              lineHeight: 1.3,
              fontSize: '12px',
              marginTop: 2,
              marginBottom: 0,
            }}
          >
            Buyers can resell your shoutouts <br />
            if you allow them, <br />
            <span style={{ color: '#ffffff66' }}>
              boosting your journey to
              <br /> a $1B personal brand valuation.
              <br /> Learn more{' '}
            </span>{' '}
          </Typography>
        </div>
        <div
          style={{
            margin: '10px auto 0',
          }}
        >
          <Typography
            className={props.fontStyle}
            sx={{ color: '#ffbf12', fontSize: '12px', textAlign: 'center', marginBottom: 1 }}
          >
            3. Shoutout Automatic Delivery{' '}
          </Typography>
          <Typography
            className={classes.textOpenSans}
            sx={{ display: 'block', color: '#ffffff77', fontSize: '11px', textAlign: 'center' }}
          >
            shoutouts are delivered automatically <br />
            to buyers on your instagram story.
            <br /> buyers can also share the shoutout <br />
            to their own story, making the process
            <br />
            seamless and engaging.
            <br />
            <span
              style={{
                fontSize: '10px',
                lineHeight: '11px',
                paddingTop: '14px',
                fontWeight: 'bold',
              }}
            >
              below, you can see how the story shoutout
              <br /> will appear on the creator’s instagram story.{' '}
            </span>
          </Typography>
          <img
            src={'/images/shoutout-thank-you.png'}
            alt="Thank You Mention Digital Card"
            style={{
              display: 'block',
              margin: '8px auto 0',
              position: 'relative',
              width: '160px',
              zIndex: 6,
            }}
          />{' '}
          <Typography
            className={props.fontStyle}
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              lineHeight: 1.3,
              fontSize: '12px',
              marginTop: 2,
              marginBottom: 5,
            }}
          >
            Buyers can resell your shoutouts <br />
            if you allow them, <br />
            <span style={{ color: '#ffffff66' }}>
              boosting your journey to
              <br /> a $1B personal brand valuation.
              <br /> Learn more{' '}
            </span>{' '}
          </Typography>
        </div>
      </SwipeableViews>
    </Box>
  );
}
