import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../../store/store';
// import "../desktop/home/style.css";
import './style.css';
import '../../../styles/app-style.css';
import '../../../styles/XD_home.css';
// import store from "../../store/store.js";

import LongLogo from '../../../images/ps-logo-one-row.png';
import YoutubeLogoBW from '../../../images/YoutubeLogoBW.png';
import InstagramLogoBW from '../../../images/InstagramLogoBW.png';
import FacebookLogoBW from '../../../images/FacebookLogoBW.png';
import PSLogo from '../../../images/PassionStocksIcon.png';
import PSLBckg from '../../../images/PSLBckg.png';
import PSLogoYellow from '../../../images/ps-icon.png';
import StarsEyesEmoji from '../../../images/emoji-stars-eyes.png';
import Done from '../../../images/confirm.png';
import ArrowDown from '../../../images/arrow-down-btn.png';
import DoubleArrowDown from '../../../images/double-arrow-down-white.png';
import WhiteArrowDown from '../../../images/arrow-down-white-btn.png';

import { IconButton, Alert, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import isVarNegative from '../../../tools/isVarNegative';
import EstimateSlide from './Estimate';
// import { scrollRestOfSlide } from './utiles';
import isMobileDevice from '../../../utiles/isMobileDevice';
import WelcomeSlide from './Welcome';
import PersonalBrandProcess from './PersonalBrandProcess';
import Copy1 from './Copy1';
import OneProductMarketplaceSteps from './OneProductMarketplaceSteps';
import Copy2 from './Copy2';

import Copy3 from './Copy3';
import ScalabilityDrivesSteps from './ScalabilityDrivesSteps';
import Copy5 from './Copy5';
import CommunityImpactSteps from './CommunityImpactSteps';
import OurMarketplace from './OurMarketplace';
import Copy4 from './Copy4';
import TreeChart from './TreeChart';
import Copy6 from './Copy6';
import RewardYourCommunity from './RewardYourCommunity';
import Copy7 from './Copy7';
import Creators__OpeningSlide from './OpeningSlide';
import { makeStyles } from '@mui/styles';
import Copy1A from './Copy1A';
import Copy1B from './Copy1B';
import Copy1C from './Copy1C';
import Footer from '../common-slides/Footer';
import PageWithScrollArrows from '../../../components/buttons/PageWithScrollArrows';
import JoinTheWaitlistSlide from '../common-slides/JoinTheWaitlist';
import Copy8 from './Copy8';
// const selectWindowInnerSize = (state) => state.windowInnerSize;

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectWaitlistSelect = (state) => state.waitlistSelect;

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

const CreatorsPage = (props) => {
  const classes = useStyles();

  const windowInnerSize = useSelector(selectWindowInnerSize);
  useEffect(() => {
    if (!windowInnerSize) {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: null,
      });
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.outerHeight, width: window.outerWidth },
      });
    }
  }, [windowInnerSize]);

  // navigate to the last position:
  const location = useLocation();

  useEffect(() => {
    const scrollPositionUp = document.getElementById('LandingPage');
    if (location.state?.from === 'wishlist') {
      scrollPositionUp.scrollTo({
        top: windowInnerSize.height * 19,
      });
    }
    if (location.state?.from === 'footer') {
      scrollPositionUp.scrollTo({
        top: windowInnerSize.height * 21,
      });
    }
  }, [location]);

  //
  useEffect(() => {
    setTimeout(() => {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.innerHeight, width: window.innerWidth },
      });
    }, 1000);
  }, []);

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  return (
    <PageWithScrollArrows id={'LandingPage1'}>
      {/* <div id="LandingPage" 
        // className="LandingPage"
        > */}
      <Box sx={{ position: 'relative', width: '100vw', margin: 0, padding: 0 }}>
        <div style={{ height: 0 /*22 * windowInnerSize.height*/ }}>
          <div style={{ position: 'relative' }}>
            <Typography
              className={classes.textOpenSans}
              variant="subtitle1"
              onClick={() => props.setIndex(1)}
              sx={{ position: 'absolute', top: 0, right: 0, padding: 4, cursor: 'pointer' }}
            >
              Creators’ Agency
            </Typography>{' '}
            <Creators__OpeningSlide />
          </div>{' '}
          <div>
            <Copy1A />
          </div>
          <div id="EstimateSlide">
            <EstimateSlide isPhone={isPhone} />
          </div>
          <div>
            <Copy1B />
          </div>
          <div>
            <div id="EstimateSlide">
              <PersonalBrandProcess isPhone={isPhone} />
            </div>
            <div>
              <Copy1C />
            </div>
            <div id="EstimateSlide">
              <WelcomeSlide isPhone={isPhone} />
            </div>
            <div>
              <Copy1 />
            </div>
            <div>
              <OneProductMarketplaceSteps />
            </div>
            <div>
              <Copy2 />
            </div>
            <div>
              <OurMarketplace />
            </div>
            <div>
              <Copy3 />
            </div>
            <div>
              {/* <ScalabilityDrivesSteps /> */}
            </div>
            <div>
              <Copy4 />
            </div>
            <div>
              <TreeChart />
            </div>
            <div>
              <Copy5 />
            </div>
            <div>
              <Copy8 />
            </div>
            <div>
              <JoinTheWaitlistSlide />
            </div>
          </div>
          <Footer />
        </div>
        {/* </div> */}
      </Box>
    </PageWithScrollArrows>
  );
};
export function isValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default CreatorsPage;
