import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Agency__Copy1() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);

  useEffect(() => {
    const Copy1AElement = document.getElementById('Copy1A');
    const calculatedMarginA = (window.innerHeight - Copy1AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );

    const Copy1BElement = document.getElementById('Copy1B');
    const calculatedMarginB = (window.innerHeight - Copy1BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy1A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 6 }}
          variant="h2"
        >
          So, What do you do as
          <br />
          a creator agency <br />
          on PassionStocks?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          you implement
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
        >
          strategies to maximize <br />
          shoutouts sales
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 6 }}
        >
          For the creators you manage.
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.1, marginBottom: 1 }}
        >
          Wondering how to
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 6 }}
          variant="h2"
        >
          maximize <br />
          shoutout sales <br />
          for your creators
          <br />& hit $1B valuation?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          you need to know how to:
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          1. control shoutout <br />
          supply & demand{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          to maintain shoutout price{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          2. add Packages & perks <br />
          to shoutout sales
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          to boost shoutouts sales
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          3. collaborate <br />
          with other creators
          <br />
          On passionstocks
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          to boost shoutouts sales{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          4. select resellers <br />
          from shoutout buyers
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          to boost shoutouts sales
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          5. Facilitate merging
          <br />
          personal brand assets <br />
          with other creators
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 9 }}
        >
          to collectively achieve <br />a $1B valuation
        </Typography>

        <JoinTheWaitlistButton pageType={"agency"}/>
      </div>

      <div id="Copy1B" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          What’s in It for your{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 6 }}
        >
          Creator agency?
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          You can set a{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          Management fee
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 6 }}
        >
          {' '}
          from the shoutout <br />
          sales revenue.{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          & you can build{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          $1B creator agency{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          on your own or <br />
          with other creator Agencies.
        </Typography>{' '}
      </div>
    </Container>
  );
}
