import React from "react";
import { Box } from "@mui/material";

const HorizontalLine = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "4px",
        backgroundColor: "#707070", // Matches the gray color
        borderRadius: "2px",
        opacity: 0.7,
      }}
    />
  );
};

export default HorizontalLine;
