import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PSIcon from '../../../../../images/ps-icon.png';
import Train from '../../../../../images/train.svg';
import Passengers from '../../../../../images/passengers-driver.svg';
import BusyCreator from '../../../../../images/busy-creator.svg';
import BusyCreatorKey from '../../../../../images/busy-creator-key.svg';
import Manager from '../../../../../images/manager.svg';
import DoubleArrow from '../../../../../images/double-arrow-down-white.png';
import ArrowDown from '../../../../../images/arrow-down-btn.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../../../../store/store';
import { Close } from '@material-ui/icons';
import JoinTheWaitlistButton from '../../../../../components/buttons/JoinTheWaitlist';

const selectWindowInnerSize = (state) => state.windowInnerSize;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    // height: '',
    overflow: 'hidden',
  },
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  trainSignContainer: {
    top: '17%',
    left: '29%',
    width: '42%',
    height: '17%',
    position: 'absolute',
  },
  trainStationLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '100%',
    color: '#fff',
    width: '100%',
  },

  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  navButtonLeft: {
    left: 0,
  },
  navButtonRight: {
    right: 0,
  },
  stationName: {
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    cursor: 'pointer',
    // boxShadow: '0 0 0 20px #ffbf12',
    borderRadius: '100px',
    '&:hover': {
      boxShadow: '0 0 0 7px #ffbf127a', // Adjust halo color as needed
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)', // Adjust halo color as needed
    },
  },
});

const PSAccountManager = (props) => {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const classes = useStyles();

  // set train sign height - using for fit thr station logo
  // const [height, setHeight] = useState();

  const handleJoinButton = () => {
    props.handleCloseDialog();
    setTimeout(() => {
      const element = document.getElementById('LandingPage');

      element.scrollTo({
        top: element.scrollHeight - windowInnerSize.height * 2,
      });

      //   store.dispatch({
      //     type: 'ui/waitlistSelect/add',
      //     payload: 'manager',
      //   });
    }, 500);
  };
  return (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        background: '#121212',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        edge="start"
        color="#fff"
        onClick={props.handleCloseDialog}
        aria-label="close"
        sx={{
          width: '40px',
          position: 'relative',
          color: '#fff',
          padding: '10px',
          margin: '0 0 10px',
        }}
      >
        <Close />
      </IconButton>{' '}
      <div
        style={{
          position: 'relative',
          // margin: 'auto 0',
          //   top: '40%',
          //   transform: 'translate(0%,-50%)',
          height: 'fit-content',
        }}
      >
        <Container sx={{ width: 'fit-content', margin: { xs: '0 auto 10px', xl: '0 auto 50px' } }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: props.isPhone ? '90px' : '130px',
              width: props.isPhone ? '310px' : '550px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '140px',
              }}
            >
              <Typography
                className={classes.textMonarcha}
                sx={{ color: '#ffbf12', fontSize: { xs: '13px', sm: '18px' }, textAlign: 'center' }}
              >
                Busy creator?
              </Typography>{' '}
              <img
                src={BusyCreator}
                alt="Busy creator"
                style={{ width: props.isPhone ? '70px' : '130px' }}
              />
            </div>
            <img
              src={BusyCreatorKey}
              alt="Busy creator key"
              style={{ width: props.isPhone ? '22px' : '35px', height: 'fit-content' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '130px',
              }}
            >
              <Typography
                className={classes.textMonarcha}
                sx={{
                  color: '#ffbf12',
                  fontSize: { xs: '13px', sm: '18px' },
                  lineHeight: 1.2,
                  textAlign: 'center',
                  marginBottom: 2,
                }}
              >
                Passionstocks <br />
                Account manager
              </Typography>
              <img src={Manager} alt="Manager" style={{ width: props.isPhone ? '14px' : '27px' }} />
            </div>
          </div>
        </Container>
        <Container
          sx={{
            marginTop: props.isPhone ? '16px' : '20px',
            width: { xs: '90%', sm: '80%' },
            marginBottom: 2,
          }}
        >
          <Typography
            className={classes.textOpenSans}
            sx={{
              textAlign: 'center',
              color: '#909090',
              fontSize: { xs: '13px', sm: '16px' },
              lineHeight: 1.3,
            }}
          >
            Someone has got to drive the train to NASDAQ;
            <br /> It can be you, but if you have a busy schedule,
            <br /> you can authorize a personal brand manager to handle your PassionStocks account.
            You can invite a manager you already know or find one on PassionStocks.{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="https://passionstocks.helpsite.com/articles/124048-set-forget-settings-for-busy-creators"
            >
              Learn More
            </a>
          </Typography>
          <Typography
            className={classes.textOpenSans}
            sx={{
              fontSize: { xs: '11px', sm: '14px' },
              lineHeight: 1.3,
              textAlign: 'center',
              color: '#909090',
              marginTop: '20px',
            }}
          >
            If you’re interested in helping creators achieve a $1B personal brand valuation, whether
            you’re already managing creators or just starting,
            <br /> becoming a personal brand manager is a great opportunity.
            <br />
            <br />
            You can start small, initially managing one or two creators. <br />
            As you improve your management score, you can gradually expand it into a full-time
            business. <br />
            Enjoy flexible, growing income, free from location or time constraints, and have fun
            helping creators build $1B personal brands.
            <a
              style={{ textDecoration: 'underline' }}
              href="https://passionstocks.helpsite.com/articles/106684-what-are-the-benefits-for-me-as-a-passionstocks-account-manager"
            >
              Learn More
            </a>
          </Typography>
        </Container>
        {/* <div
        style={{
          position: 'relative',
          margin: '0 auto 40px',
          //   bottom: '5%',
          //   left: '50%',
          //   transform: 'translate(-50%, -0%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      > */}

        {/* <Container>
        <Button
          className={classes.textOpenSans}
          sx={{
            //   position: 'absolute',
            //   left: '50%',
            //   bottom: 0,
            //   transform: 'translate(-50%,0)',
            textTransform: 'none',
            color: 'black',
            fontSize: '14px',
            lineHeight: 1.4,
            padding: '5px 60px',
            background: '#ffbf12',
            borderRadius: '50px',
            margin: '40px auto 0',
            display: 'block',
            '&:hover': { background: '#ffbf12' },
          }}
          // variant="outlined"
          onClick={() => {
            props.handleCloseDialog();
            setTimeout(() => {
              const element = document.getElementById('LandingPage');

              element.scrollTo({
                top: element.scrollHeight - windowInnerSize.height * 2,
              });

              //   store.dispatch({
              //     type: 'ui/waitlistSelect/add',
              //     payload: 'manager',
              //   });
            }, 500);
          }}        >
          Join the Waitlist
        </Button>
      </Container> */}
<Container sx={{marginBottom:8}}>
        <JoinTheWaitlistButton variant={'small'} onClick={handleJoinButton} />
  </Container>    </div>
      <div />
    </Box>
  );
};

export default PSAccountManager;
