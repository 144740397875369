import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Agency__Copy4(props) {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);

  useEffect(() => {
    const Copy4aElement = document.getElementById('Copy4A');
    const calculatedMarginA = (window.innerHeight - Copy4aElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy4A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          sx={{ color: '#ffbf12', marginBottom: 10 }}
          variant="h3"
        >
          <span style={{ color: '#fff' }}>
            Want to learn more <br />
            about agency account <br />
            on PassionStocks?{' '}
          </span>
          <br />
          <br />
          —Join the waitlist <br />
          as a Creator Agency.
        </Typography>
        <JoinTheWaitlistButton pageType={"agency"}/>
        <br />
        <br />
        <br />
        <Container sx={{ width: '250px' }}>
          <HorizontalLine />
        </Container>
      </div>
    </Container>
  );
}
