import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';

import tiktokIcon from '../../../../images/tiktok-icon.svg';
import instagramIcon from '../../../../images/instagram-icon.svg';
import youtubeIcon from '../../../../images/youtube-icon.svg';
import unicorn from '../../../../images/unicorn.png';
import stocks from '../../../../images/stocks.svg';
import cash from '../../../../images/cash.svg';
import ArrowDown from '../../../../images/arrow-down-btn.png';
import DoubleArrowDown from '../../../../images/double-arrow-down-white.png';

import SliderEstimate from './components/Slider/Slider_desktop';

import { fetchData, calcBrandValues } from './dataFetcherBrandCalc';
import { useEffect, useState } from 'react';
import formatNumberToAbbString from '../../../../helpers/formatNumberToAbbString';
import { Link } from 'react-router-dom';
import SliderLayout from './components/Slider';
import UnicornIndicatorLayout from './components/UnicornIndicator';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
}));

const selectWindowInnerSize = (state) => state.windowInnerSize;

const Agency__EstimateSlide = (props) => {
  const classes = useStyles();

  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [brandCalculatorData, setBrandCalculatorData] = useState(null);
  useEffect(() => {
    async function fetchDataAndUpdate() {
      const fetchedData = await fetchData();
      setBrandCalculatorData(fetchedData);
    }
    fetchDataAndUpdate();
  }, []);

  const [SMfollowers, setSMfollowers] = useState({ tiktok: 0, instagram: 0, youtube: 0 });
  const [totalEarnings, setTotalEarnings] = useState({ min: 0, max: 0 });
  const [totalWorth, settotalWorth] = useState({ min: 0, max: 0 });
  const [unicornRatio, setunicornRatio] = useState(0);
  function calcBrand(followers) {
    // console.log(followers);
    return calcBrandValues(followers, brandCalculatorData);
  }
  function setTiktokFollowers(followers) {
    // console.log('p' + followers);
    let temp = { ...SMfollowers, tiktok: followers };
    // console.log(temp);
    setSMfollowers(temp);
  }
  function setInstagramFollowers(followers) {
    let temp = { ...SMfollowers, instagram: followers };
    setSMfollowers(temp);
  }
  function setYoutubeFollowers(followers) {
    let temp = { ...SMfollowers, youtube: followers };
    setSMfollowers(temp);
  }
  useEffect(() => {
    if (brandCalculatorData) {
      let totalFollowers = SMfollowers.tiktok + SMfollowers.instagram + SMfollowers.youtube;
      let brandValues = calcBrand(totalFollowers, brandCalculatorData);
      setTotalEarnings(brandValues.earnings);
      settotalWorth(brandValues.worth);
      let tempUnicornRatio = +brandValues?.worth.min / 10000000;
      tempUnicornRatio = tempUnicornRatio > 100 ? 100 : tempUnicornRatio;
      setunicornRatio(tempUnicornRatio);
    }
  }, [SMfollowers, brandCalculatorData]);

  return (
    brandCalculatorData && (
      <div
        className={classes.root}
        style={{
          position: 'relative',
          width: '100vw',
          minHeight: windowInnerSize.height,
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20vh',
          zIndex: '2',
        }}
      >
        <div style={{ position: 'relative', margin: 'auto 0', padding: '50px 0' }}>
          <p
            className={'LandingPage__WhereTxt unnamed-character-style-1'}
            style={{
              fontSize: props.isPhone ? '18px' : '32px',
              lineHeight: props.isPhone ? '24px' : '',
              textShadow: props.isPhone
                ? '0 0 0.2vw #fff, 0 0 2.35vw #fff, 0 0 5.1vw #ffbf12'
                : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
            }}
          >
            Check Your Creator Agency’s Worth
          </p>
          <p
            className="headline"
            style={{
              fontSize: props.isPhone ? '12px' : '16px',
              lineHeight: props.isPhone ? '15px' : '20px',
              color: '#a1a1a1',
              fontWeight: 'lighter',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            Use the slider or enter your creators’ combined social media follower count to estimate
            your agency’s worth, IPO readiness and projected cash earnings on PassionStocks.
            <br /> your creators’ social media worth directly impacts your agency’s worth. This
            calculator is for content creators agencies of all types.
          </p>
          <div
            // container
            // spacing={3}
            style={{
              display: 'flex',
              width: 'fit-content',
              position: 'relative',
              margin: '0 auto',
              alignItems: 'center',
              flexDirection: props.isPhone ? 'row' : 'column',
            }}
          >
            {/* {!props.isPhone && (
              <Typography
                className="headline"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  fontSize: '11px',
                  width: '163px',
                  padding: '0 24px 0 0',
                  textAlign: 'center',
                }}
              >
                *Account Worth Range
              </Typography>
            )} */}

            <SliderLayout
              icon={tiktokIcon}
              alt="Tiktok"
              padding="15px 30px 12px 40px"
              width="24px"
              calcBrand={calcBrand}
              setFollowers={setTiktokFollowers}
              isPhone={props.isPhone}
            />
            {/* </Grid> */}
            <SliderLayout
              icon={instagramIcon}
              alt="Instagram"
              padding="20px 35px 12px "
              width="27px"
              calcBrand={calcBrand}
              setFollowers={setInstagramFollowers}
              isPhone={props.isPhone}
            />

            <SliderLayout
              youtube
              icon={youtubeIcon}
              alt="Youtube"
              padding="20px 35px 12px"
              width="29px"
              calcBrand={calcBrand}
              setFollowers={setYoutubeFollowers}
              isPhone={props.isPhone}
            />
          </div>
          {props.isPhone && (
            <p
              className="headline"
              style={{
                margin: '0 0 0 20px',
                color: '#909090',
                fontSize: '10px',
                lineHeight: '20px',
                fontWeight: 'lighter',
              }}
            >
              <span style={{ fontSize: '20px', lineHeight: '0' }}>_</span>
              <br />
              <span style={{ fontSize: '6px', fontWeight: 'bolder', verticalAlign: 'text-bottom' }}>
                |{' '}
              </span>
              Account worth range
            </p>
          )}
          <p
            className={'LandingPage__WhereTxt unnamed-character-style-1'}
            style={{
              fontSize: props.isPhone ? '18px' : '23px',
              lineHeight: props.isPhone ? '22px' : '',
              marginBottom: props.isPhone ? '10px' : '',
              margin: props.isPhone ? '35px auto 10px' : '12px auto 0px',
              textShadow: props.isPhone
                ? '0 0 0.2vw #fff, 0 0 2.35vw #fff, 0 0 5.1vw #ffbf12'
                : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
            }}
          >
            Stock exchange listing (IPO) readiness{' '}
          </p>
          <div className="MyBrandStats__IpoProgressContainer">
            <UnicornIndicatorLayout
              isPhone={props.isPhone}
              totalWorth={totalWorth}
              unicornRatio={unicornRatio}
            />
            <div>
              <div
                className="Estimate__WorthRangesContainer unnamed-character-style-1"
                style={{
                  display: props.isPhone ? 'block' : 'flex',
                  marginTop: props.isPhone ? '20px' : '30px',
                }}
              >
                <div style={props.isPhone ? null : { maxWidth: '320px', margin: '0 20px' }}>
                  <p
                    className="unnamed-character-style-1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      margin: '0',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#ffbf12',
                    }}
                  >
                    agency’s Worth
                    <img
                      src={stocks}
                      alt="Stocks value"
                      style={{ width: '14px', marginLeft: '5px' }}
                    />
                  </p>
                  <p
                    style={{
                      fontWeight: 'normal',
                      color: '#707070',
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                    }}
                  >
                    *your current Agency’s worth <br />
                    ranges from
                  </p>
                  <p
                    style={{
                      color: '#ffbf12',
                      fontSize: '20px',
                      lineHeight: '18px',
                      margin: '0 10px',
                      textAlign: 'center',
                    }}
                  >
                    ~ ${formatNumberToAbbString(totalWorth?.min)} - $
                    {formatNumberToAbbString(totalWorth?.max)}
                  </p>
                </div>
                <div
                  style={
                    props.isPhone ? { marginTop: '20px' } : { maxWidth: '320px', margin: '0 20px' }
                  }
                >
                  <p
                    className="unnamed-character-style-1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      margin: '0',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#ffbf12',
                    }}
                  >
                    Projected cash earnings{' '}
                    <img
                      src={cash}
                      alt="Cash earning"
                      style={{ width: '16px', marginLeft: '5px' }}
                    />
                  </p>
                  <p
                    style={{
                      fontWeight: 'normal',
                      color: '#707070',
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                    }}
                  >
                    **your Agency’s projected earnings <br />
                    on PassionStocks (next 12 months) range from
                  </p>
                  <p
                    style={{
                      color: '#ffbf12',
                      fontSize: '20px',
                      lineHeight: '18px',
                      margin: '0 10px',
                      textAlign: 'center',
                    }}
                  >
                    ~ ${formatNumberToAbbString(totalEarnings?.min)} - $
                    {formatNumberToAbbString(totalEarnings?.max)}{' '}
                  </p>
                </div>
              </div>
              <div style={{ margin: props.isPhone ? '20px 3vw' : '10px 10px 10px 47px' }}>
                <p
                  className="headline"
                  style={{
                    color: '#707070',
                    fontSize: '12px',
                    lineHeight: '20px',
                    fontWeight: 'lighter',
                    margin: 0,
                    width: 'fit-content',
                  }}
                >
                  *The worth range reflects your creator agency’s worth, based on your creators’
                  social media accounts and projected earnings from shoutout sales on the
                  PassionStocks platform; other income is excluded.{' '}
                  <Link
                    to="https://support.passionstocks.com/articles/124970-where-does-my-personal-brand-value-come-from"
                    style={{ textDecoration: 'underline' }}
                  >
                    Learn more
                  </Link>
                  .
                </p>
                {props.isPhone && <br style={{ lineHeight: '15px' }} />}
                <p
                  className="headline"
                  style={{
                    color: '#707070',
                    fontSize: '12px',
                    lineHeight: '20px',
                    fontWeight: 'lighter',
                    margin: 0,
                    width: 'fit-content',
                  }}
                >
                  **Projected earnings are based on an estimated annual 1% conversion rate of{' '}
                  <Link to="" style={{ textDecoration: 'underline' }}>
                    your creators’ followers purchasing shoutouts
                  </Link>{' '}
                  within the PassionStocks ecosystem.{' '}
                  <Link
                    to="https://support.passionstocks.com/articles/122673-how-does-the-brand-calculator-work"
                    style={{ textDecoration: 'underline' }}
                  >
                    Learn more
                  </Link>
                  .
                </p>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div
            className="headline"
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              fontSize: '11px',
              fontWeight: 'lighter',
              marginTop: '30px',
            }}
          >
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0, lineHeight: '35px' }}>Scroll down</p>
              <img src={DoubleArrowDown} alt="Scroll down" style={{ width: '12px' }} />
            </div> */}
            
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0, lineHeight: '35px' }}>Join the waitlist now</p>
              <img
                src={ArrowDown}
                alt="Click to join the waiting list"
                style={{ width: '20px', cursor: 'pointer' }}
                onClick={() => {
                  const element = document.getElementById('LandingPage');

                  element.scrollTo({
                    top: element.scrollHeight - windowInnerSize.height * 2,
                  });
                }}
              />
            </div> */}
          </div>
        </div>
        <JoinTheWaitlistButton />{' '}
      </div>
    )
  );
};

export default Agency__EstimateSlide;
