import { Box } from '@mui/material';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CreatorsPage from './creators-slides/index';
import AgencyPage from './agency-slides';
import { withWidth } from '@material-ui/core';

function LandingPageLayout({ width, ...props }) {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  return (
    <Box>
      <SwipeableViews
        disabled
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <Box sx={{ height: '100vh', width: '100vw', }}>
          <CreatorsPage setIndex={setIndex} />
        </Box>
        <Box sx={{ height: '100vh', width: '100vw' }}>
          <AgencyPage setIndex={setIndex} isPhone={width === 'xs'} />
        </Box>
      </SwipeableViews>
    </Box>
  );
}
export default withWidth()(LandingPageLayout);
