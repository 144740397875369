import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import whiteArrow from '../../../images/arrow-down-white-btn.png';
import yellowArrow from '../../../images/arrow-down-btn.png';
import { withWidth } from '@material-ui/core';
import store from '../../../store/store';
import { useSelector } from 'react-redux';
import { keyframes } from '@emotion/react';

const selectWindowInnerSize = (state) => state.windowInnerSize;

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const ScrollArrows = ({ width, containerRef }) => {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [isYellowArrowVisible, setIsYellowArrowVisible] = useState(true);
  const [isYellowArrowDown, setIsYellowArrowDown] = useState(true);
  const [doubleArrowsScrollTo, setDoubleArrowsScrollTo] = useState(true);
  const [isWhiteArrowVisible, setIsWhiteArrowVisible] = useState(false);

  useEffect(() => {
    console.log('ScrollArrows rendered for containerRef:', containerRef.current.id);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      const scrollPosition = containerRef.current.scrollTop;
      const containerHeight = containerRef.current.clientHeight;
      const totalScrollHeight = containerRef.current.scrollHeight;

      const oneBeforeLast = totalScrollHeight - 2 * windowInnerSize.height;
      const lastSlide = totalScrollHeight - containerHeight * 1.5;

      if (scrollPosition === 0) {
        setIsYellowArrowVisible(true);
        setIsYellowArrowDown(true);
        setIsWhiteArrowVisible(false);
      } else if (scrollPosition >= oneBeforeLast && scrollPosition < lastSlide) {
        setIsYellowArrowVisible(true);
        setIsYellowArrowDown(false);
        setIsWhiteArrowVisible(true);
      } else if (scrollPosition >= lastSlide) {
        setIsYellowArrowVisible(true);
        setIsWhiteArrowVisible(false);
      }
      setDoubleArrowsScrollTo(false);
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [containerRef]);

  const scrollTo = (position) => {
    containerRef.current.scrollTo({ top: position, behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        position: containerRef.current?.id === 'LandingPage1' ? 'fixed' : 'sticky',
        bottom: '2em',
        left: containerRef.current?.id === 'LandingPage1' ? '0' : '2em',
      }}
    >
      {isYellowArrowVisible && (
        <Box
          sx={{
            position: 'absolute',
            width: 'fit-content',
            height: doubleArrowsScrollTo ? '72px' : '48px',
            bottom: '0',
            left: width === 'xs' ? '2em' : '3.5em',
            transform: 'translateX(-50%)',
          }}
        >
          <IconButton
            sx={{
              position: 'relative',
              backgroundColor: '',
              padding: '6.4px',
              '&:hover': { backgroundColor: '#ffbf1244' },
              color: 'black',
            }}
            onClick={() => {
              scrollTo(
                isYellowArrowDown
                  ? containerRef.current.scrollHeight - 2 * windowInnerSize.height
                  : 0,
              );
              setIsYellowArrowVisible(true);
              setIsYellowArrowDown(false);
              setIsWhiteArrowVisible(true);
              setDoubleArrowsScrollTo(false);
            }}
          >
            <img
              src={yellowArrow}
              alt="Yellow Arrow"
              style={{
                transform: isYellowArrowDown ? '' : 'rotate(-180deg)',
                transition: 'all .5s',
                width: '36px',
                height: '36px',
              }}
            />
          </IconButton>

          {doubleArrowsScrollTo && (
            <Box
              sx={{
                position: 'relative',
                width: '42.4px',
                height: '36px',
                margin: 'auto',
              }}
            >
              {[...Array(2)].map((_, index) => (
                <KeyboardArrowDown
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: index * 9,
                    left: '20%',
                    transform: 'translate(-70%,0)',
                    color: 'white',
                    width: '24px',
                    height: '24px',
                    // padding: 0,
                    // margin: 0,
                    animation: `${bounceAnimation} 1.5s ${index * 0.1}s infinite`,
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      )}

      {isWhiteArrowVisible && (
        <IconButton
          sx={{
            position: 'absolute',
            bottom: '0em',
            left: width === 'xs' ? '3.2em' : '4.3em',
            transform: 'translateX(-50%)',
            backgroundColor: '',
            padding: '6.4px',
            '&:hover': { backgroundColor: '#ffffff33' },
            color: 'black',
          }}
          onClick={() => {
            if (containerRef.current) {
              const scrollPosition = containerRef.current.scrollTop;
              const containerHeight = containerRef.current.clientHeight;
              const totalScrollHeight = containerRef.current.scrollHeight;

              const oneBeforeLast = totalScrollHeight - 2 * windowInnerSize.height;
              const lastSlide = totalScrollHeight - containerHeight;

              if (scrollPosition < oneBeforeLast) {
                scrollTo(oneBeforeLast + 5);
              } else {
                scrollTo(lastSlide);
              }
            }
          }}
        >
          <img src={whiteArrow} alt="White Arrow" style={{ width: '36px', height: '36px' }} />{' '}
        </IconButton>
      )}
    </Box>
  );
};

const PageWithScrollArrows = ({ width, children, id }) => {
  const containerRef = useRef(null);
  const windowInnerSize = useSelector(selectWindowInnerSize);

  return (
    <Box
      id={id}
      key={id}
      ref={containerRef}
      sx={{
        position: 'relative',
        height: windowInnerSize.height,
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        bgcolor: '#121212',
        margin: 0,
        padding: 0,
      }}
    >
      {children}
      <ScrollArrows containerRef={containerRef} width={width} />
    </Box>
  );
};

export default withWidth()(PageWithScrollArrows);
