import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import { withWidth } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
}));
const OurMarketplace = ({ width }) => {
  const classes = useStyles();

  return (
    <Box sx={{ marginTop: 6 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h6"
        sx={{
          display: 'block',
          textAlign: 'center',
          margin: '0 auto',
          marginBottom: 5,
          color: '#ffbf12',
        }}
      >
        Below, you can see our {width === 'xs' && <br />}one-product marketplace
         <br />
        designed exclusively for{width === 'xs' && <br />} Story Shoutouts{' '}
      </Typography>
      <img
        src={'/images/our-marketplace-ex.png'}
        alt="Our one-product marketplace image"
        style={{
          display: 'block',
          margin: '0 auto 50px',
          width: width === 'xs' ? '280px' : '480px',
          maxWidth: '800px',
        }}
      />
      <JoinTheWaitlistButton />
    </Box>
  );
};

export default withWidth()(OurMarketplace);
