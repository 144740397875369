import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TotalStatsExampls from '../../../../../../../images/total-stats-example.png';
import Marketplace from '../../../../../../../images/marketplace.svg';

export default function OneProduct__PricingMethod(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          On PassionStocks, <span style={{ color: '#ffbf12' }}>shoutout pricing</span> adjusts{' '}
          <br />
          dynamically, <span style={{ color: '#ffbf12' }}>
            similar to flight tickets or Uber
          </span>, <br />
          based on <span style={{ color: '#ffbf12' }}>supply & demand</span> <br />- limited
          availability of shoutouts.
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }}>
          <img src={TotalStatsExampls} alt="Figure available supply" style={{ width: '280px' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '60vw',
            }}
          >
            <Typography
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                color: '#ffffff44',
                lineHeight: 1.3,
                textAlign: 'center',
                marginTop: 0,
                marginBottom: 4,
              }}
            >
              <span style={{ color: '#ffbf12' }}>Creators</span> on PassionStocks can
              <br />
              <span style={{ color: '#ffbf12' }}>
                create custom pricing methods for shoutouts,
                <br />
                controlling how prices fluctuate
              </span>{' '}
              
              with options to <br />
              set minimum & maximum caps.
              <br />
              <br /><br/><br/>
              <span style={{ color: '#ffbf12' }}> PassionStocks Market Price</span> <br />
          helps buyers see if a shoutout price <br />
          is fair, high, or low, based on <br />
          <span style={{ color: '#ffbf12' }}>social media & market trends.</span>
      </Typography>
            <img src={Marketplace} alt="Marketplace icon" style={{ width: '120px' }} />
          </div>
        </div>
      </Container>
    </FadeIn>
  );
}
