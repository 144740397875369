import { Container, Typography } from '@mui/material';

import ThankYouMentionIcon from '../../../../images/thank-you-mention-icon.svg';
import ProcessSteps from '../../components/ProcessSteps';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { withWidth } from '@material-ui/core';
import ScalabilityDrives__Versatility from './components/ScalabilityDrives__Versatility';
import ScalabilityDrives__Scalability from './components/ScalabilityDrives__Scalability';
import ScalabilityDrives__Valuation from './components/ScalabilityDrives__Valuation';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

const ScalabilityDrivesSteps = ({ width, ...props }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 0 ? '#ffbf12' : '909090' }}
    >
      Scalability
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 1 ? '#ffbf12' : '909090' }}
    >
      Versatility
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 2 ? '#ffbf12' : '909090' }}
    >
      Valuation
    </Typography>,
  ];

  return (
    <Container sx={{ height: 'fit-content', marginBottom: 0 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{ textAlign: 'center', lineHeight: 1.1, color: '#ffbf12' }}
      >
        Scalability Drives Startup Valuation{' '}
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{ textAlign: 'center', lineHeight: 1.5, color: '#ffffff77' }}
      >
        monetization for startup valuation{' '}
      </Typography>
      <img
        src={ThankYouMentionIcon}
        alt="Graph by avatars"
        style={{ display: 'block', margin: '10px auto 35px', width: '9vw' }}
      />
      <div style={{ width: 'fit-content', margin: '20px auto 0' }}>
        <ProcessSteps
          index={index}
          setIndex={setIndex}
          length={stepsNames.length}
          stepsNames={stepsNames}
        />
      </div>
      <Container sx={{ marginTop: 12 }}>
        {index === 0 && <ScalabilityDrives__Scalability fontStyle={classes.textMonarcha} />}
        {index === 1 && <ScalabilityDrives__Versatility fontStyle={classes.textMonarcha} />}
        {index === 2 && <ScalabilityDrives__Valuation fontStyle={classes.textMonarcha} />}
      </Container>{' '}
    </Container>
  );
};

export default withWidth()(ScalabilityDrivesSteps);
