
const objValues=[
    {
        "tier": 1,
        "ringColour": "blue",
        "followers_max": 40000,
        "price": 2,
        "currency": "USD",
        "authorizedStocks": 400,
        "projectedAnualEarnings": 800,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 119.723755068142,
        "monthlyEarnings2": 1553.2056748320674,
        "anualEarnings1": 1436.685060817704,
        "anualEarnings2": 18638.46809798481,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 14366.85060817704,
        "projectedBrandWorth20": 372769.36195969617,
        "desiredBrandWorth": 745538.7239193923,
        "ambitiousBrandWorth": 1118308.0858790886
    },
    {
        "tier": 2,
        "ringColour": "blue",
        "followers_max": 100000,
        "price": 3,
        "currency": "USD",
        "authorizedStocks": 1000,
        "projectedAnualEarnings": 3000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 448.96408150553253,
        "monthlyEarnings2": 5824.5212806202535,
        "anualEarnings1": 5387.568978066391,
        "anualEarnings2": 69894.25536744304,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 53875.6897806639,
        "projectedBrandWorth20": 1397885.1073488607,
        "desiredBrandWorth": 2795770.2146977214,
        "ambitiousBrandWorth": 4193655.322046582
    },
    {
        "tier": 3,
        "ringColour": "green",
        "followers_max": 250000,
        "price": 5,
        "currency": "USD",
        "authorizedStocks": 2500,
        "projectedAnualEarnings": 12500,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 1870.683672939719,
        "monthlyEarnings2": 24268.838669251054,
        "anualEarnings1": 22448.204075276626,
        "anualEarnings2": 291226.06403101265,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 224482.04075276625,
        "projectedBrandWorth20": 5824521.280620253,
        "desiredBrandWorth": 11649042.561240505,
        "ambitiousBrandWorth": 17473563.84186076
    },
    {
        "tier": 4,
        "ringColour": "green",
        "followers_max": 500000,
        "price": 7.5,
        "currency": "USD",
        "authorizedStocks": 5000,
        "projectedAnualEarnings": 37500,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 5612.051018819156,
        "monthlyEarnings2": 72806.51600775316,
        "anualEarnings1": 67344.61222582987,
        "anualEarnings2": 873678.192093038,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 673446.1222582987,
        "projectedBrandWorth20": 17473563.84186076,
        "desiredBrandWorth": 34947127.68372152,
        "ambitiousBrandWorth": 52420691.525582284
    },
    {
        "tier": 5,
        "ringColour": "purple",
        "followers_max": 1000000,
        "price": 10,
        "currency": "USD",
        "authorizedStocks": 10000,
        "projectedAnualEarnings": 100000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 14965.469383517751,
        "monthlyEarnings2": 194150.70935400843,
        "anualEarnings1": 179585.632602213,
        "anualEarnings2": 2329808.512248101,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 1795856.32602213,
        "projectedBrandWorth20": 46596170.24496202,
        "desiredBrandWorth": 93192340.48992404,
        "ambitiousBrandWorth": 139788510.73488608
    },
    {
        "tier": 6,
        "ringColour": "purple",
        "followers_max": 2500000,
        "price": 12,
        "currency": "USD",
        "authorizedStocks": 25000,
        "projectedAnualEarnings": 300000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 44896.408150553245,
        "monthlyEarnings2": 582452.1280620253,
        "anualEarnings1": 538756.897806639,
        "anualEarnings2": 6989425.536744304,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 5387568.978066389,
        "projectedBrandWorth20": 139788510.73488608,
        "desiredBrandWorth": 279577021.46977216,
        "ambitiousBrandWorth": 419365532.20465827
    },
    {
        "tier": 7,
        "ringColour": "purple",
        "followers_max": 5000000,
        "price": 15,
        "currency": "USD",
        "authorizedStocks": 50000,
        "projectedAnualEarnings": 750000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 112241.02037638314,
        "monthlyEarnings2": 1456130.3201550634,
        "anualEarnings1": 1346892.2445165976,
        "anualEarnings2": 17473563.84186076,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 13468922.445165977,
        "projectedBrandWorth20": 349471276.8372152,
        "desiredBrandWorth": 698942553.6744304,
        "ambitiousBrandWorth": 1048413830.5116456
    },
    {
        "tier": 8,
        "ringColour": "yellow",
        "followers_max": 7500000,
        "price": 20,
        "currency": "USD",
        "authorizedStocks": 75000,
        "projectedAnualEarnings": 1500000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 224482.04075276628,
        "monthlyEarnings2": 2912260.640310127,
        "anualEarnings1": 2693784.489033195,
        "anualEarnings2": 34947127.68372152,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 26937844.890331954,
        "projectedBrandWorth20": 698942553.6744304,
        "desiredBrandWorth": 1397885107.3488607,
        "ambitiousBrandWorth": 2096827661.023291
    },
    {
        "tier": 9,
        "ringColour": "yellow",
        "followers_max": 10000000,
        "price": 40,
        "currency": "USD",
        "authorizedStocks": 100000,
        "projectedAnualEarnings": 4000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 598618.77534071,
        "monthlyEarnings2": 7766028.374160337,
        "anualEarnings1": 7183425.30408852,
        "anualEarnings2": 93192340.48992404,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 71834253.0408852,
        "projectedBrandWorth20": 1863846809.798481,
        "desiredBrandWorth": 3727693619.596962,
        "ambitiousBrandWorth": 5591540429.395443
    },
    {
        "tier": 10,
        "ringColour": "yellow",
        "followers_max": 25000000,
        "price": 50,
        "currency": "USD",
        "authorizedStocks": 250000,
        "projectedAnualEarnings": 12500000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 1870683.6729397187,
        "monthlyEarnings2": 24268838.669251055,
        "anualEarnings1": 22448204.075276624,
        "anualEarnings2": 291226064.03101265,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 224482040.75276625,
        "projectedBrandWorth20": 5824521280.620253,
        "desiredBrandWorth": 11649042561.240505,
        "ambitiousBrandWorth": 17473563841.86076
    },
    {
        "tier": 11,
        "ringColour": "yellow",
        "followers_max": 50000000,
        "price": 60,
        "currency": "USD",
        "authorizedStocks": 500000,
        "projectedAnualEarnings": 30000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 4489640.815055325,
        "monthlyEarnings2": 58245212.80620253,
        "anualEarnings1": 53875689.7806639,
        "anualEarnings2": 698942553.6744304,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 538756897.806639,
        "projectedBrandWorth20": 13978851073.488607,
        "desiredBrandWorth": 27957702146.977215,
        "ambitiousBrandWorth": 41936553220.46582
    },
    {
        "tier": 12,
        "ringColour": "yellow",
        "followers_max": 100000000,
        "price": 70,
        "currency": "USD",
        "authorizedStocks": 1000000,
        "projectedAnualEarnings": 70000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 10475828.568462426,
        "monthlyEarnings2": 135905496.5478059,
        "anualEarnings1": 125709942.8215491,
        "anualEarnings2": 1630865958.5736709,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 1257099428.215491,
        "projectedBrandWorth20": 32617319171.47342,
        "desiredBrandWorth": 65234638342.94684,
        "ambitiousBrandWorth": 97851957514.42026
    },
    {
        "tier": 13,
        "ringColour": "yellow",
        "followers_max": 250000000,
        "price": 90,
        "currency": "USD",
        "authorizedStocks": 2500000,
        "projectedAnualEarnings": 225000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 33672306.11291494,
        "monthlyEarnings2": 436839096.046519,
        "anualEarnings1": 404067673.3549793,
        "anualEarnings2": 5242069152.558228,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 4040676733.549793,
        "projectedBrandWorth20": 104841383051.16455,
        "desiredBrandWorth": 209682766102.3291,
        "ambitiousBrandWorth": 314524149153.49365
    },
    {
        "tier": 14,
        "ringColour": "yellow",
        "followers_max": 500000000,
        "price": 110,
        "currency": "USD",
        "authorizedStocks": 5000000,
        "projectedAnualEarnings": 550000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 82310081.60934763,
        "monthlyEarnings2": 1067828901.4470464,
        "anualEarnings1": 987720979.3121716,
        "anualEarnings2": 12813946817.364557,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 9877209793.121716,
        "projectedBrandWorth20": 256278936347.29114,
        "desiredBrandWorth": 512557872694.5823,
        "ambitiousBrandWorth": 768836809041.8734
    },
    {
        "tier": 15,
        "ringColour": "yellow",
        "followers_max": 750000000,
        "price": 130,
        "currency": "USD",
        "authorizedStocks": 7500000,
        "projectedAnualEarnings": 975000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 145913326.48929808,
        "monthlyEarnings2": 1892969416.2015822,
        "anualEarnings1": 1750959917.8715768,
        "anualEarnings2": 22715632994.418987,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 17509599178.715767,
        "projectedBrandWorth20": 454312659888.37976,
        "desiredBrandWorth": 908625319776.7595,
        "ambitiousBrandWorth": 1362937979665.1392
    },
    {
        "tier": 16,
        "ringColour": "yellow",
        "followers_max": 1000000000,
        "price": 150,
        "currency": "USD",
        "authorizedStocks": 10000000,
        "projectedAnualEarnings": 1500000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 224482040.75276625,
        "monthlyEarnings2": 2912260640.310127,
        "anualEarnings1": 2693784489.033195,
        "anualEarnings2": 34947127683.72152,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 26937844890.33195,
        "projectedBrandWorth20": 698942553674.4304,
        "desiredBrandWorth": 1397885107348.8608,
        "ambitiousBrandWorth": 2096827661023.2913
    },
    {
        "tier": 17,
        "ringColour": "unicorn",
        "followers_max": 2000000000,
        "price": 180,
        "currency": "USD",
        "authorizedStocks": 20000000,
        "projectedAnualEarnings": 3600000000,
        "monthlyGrowthRate1": 0.05,
        "monthlyGrowthRate2": 0.3,
        "timePeriod": 12,
        "monthlyEarnings1": 538756897.8066391,
        "monthlyEarnings2": 6989425536.744304,
        "anualEarnings1": 6465082773.679668,
        "anualEarnings2": 83873106440.93164,
        "anualGrowthRate1": 0.7958563260221301,
        "anualGrowthRate2": 22.298085122481012,
        "dailyGrowthRate1": 0.0016276620118331753,
        "dailyGrowthRate2": 0.008783828877650723,
        "projectedBrandWorth10": 64650827736.796684,
        "projectedBrandWorth20": 1677462128818.6328,
        "desiredBrandWorth": 3354924257637.2656,
        "ambitiousBrandWorth": 5032386386455.898
    }
]

export default function moneyCalculatorValues(state = objValues, action) {
    switch (action.type) {
        case 'ui/moneyCalculatorValues/add': {
            return action.payload
        }
        case 'ui/moneyCalculatorValues/delete': {
            return false
        }

        default:
            return state
    }
}