import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy1B(props) {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);

  useEffect(() => {
    const Copy1BaElement = document.getElementById('Copy1BA');
    const calculatedMarginA = (window.innerHeight - Copy1BaElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy1BA" style={{ margin: `${marginsA}px auto` }}>
        <Typography className={classes.textMonarcha} sx={{ color: '#ffbf12' ,marginBottom:8}} variant="h3">
          <span style={{ color: '#fff' }}> Build a</span> <br />
          $1 billion personal brand startup <br />
          <span style={{ color: '#fff' }}> With PassionStocks</span>
        </Typography>
        <Typography className={classes.textMonarcha} sx={{ color: '#ffbf12' }} variant="h3">
          <span style={{ color: '#fff' }}> —no </span>
          <br />
          capital, company registration, <br />
          or trademark <br />
          <span style={{ color: '#fff' }}> required to get started.</span>
        </Typography>
      </div>
    </Container>
  );
}
