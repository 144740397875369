import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../layouts/fade-in';
import BrandSynergy from '../../../../../../images/brand-synergy.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

export default function ScalabilityDrives__Valuation(props) {
  const classes = useStyles();

  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          Build a $1B personal brand by{' '}
          <span style={{ color: '#ffbf12' }}>
            converting followers into buyers,
            <br />
            like startups turning users into customers.
            <br />
            <br />
            Selling shoutouts on PassionStocks ties* your Personal brand valuation
            <br />
            to follower growth across all your social media platforms*.
            <br />
            <br />
            As your followers compound & convert, your brand value increases.
          </span>
          <br />
          <br />
          Achieving a $1B brand can <span style={{ color: '#ffbf12' }}>also</span> be done{' '}
          <span style={{ color: '#ffbf12' }}>collectively</span> by partnering <br />
          with other creators to sell shoutouts and/or merging brand assets.
        </Typography>
        <Typography
          className={classes.textOpenSans}
          variant="subtitle1"
          sx={{
            position: 'relative',
            display: 'block',
            fontSize: '12px',
            color: '#ffffff44',
            lineHeight: 1.3,
            width: '65%',
            marginRight: 'auto !important',
            marginLeft: 'auto !important',
          }}
        >
          *Valuation depends on multiple factors, such as follower engagement and whether buyers
          come from your follower base, among others. Learn more.{' '}
        </Typography>
        <br />
        <img
          src={BrandSynergy}
          alt="Brand synergy"
          style={{ display: 'block', margin: '0 auto', width: '50vw', paddingRight: '5%' }}
        />
      </Container>
    </FadeIn>
  );
}
