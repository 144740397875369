import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Agency__Copy1A(props) {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);

  useEffect(() => {
    const Copy1AaElement = document.getElementById('Copy1AA');
    const calculatedMarginA = (window.innerHeight - Copy1AaElement.clientHeight) / 2;
    setmarginsA(
      window.innerHeight / 4,
      // calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const Copy1AbElement = document.getElementById('Copy1AB');
    const calculatedMarginB = (window.innerHeight - Copy1AbElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy1AA" style={{ margin: `${marginsA}px auto` }}>
        <Typography className={classes.textMonarcha} sx={{ color: '#ffbf12' }} variant="h3">
          <span style={{ color: '#fff' }}> Creator agencies building</span> <br />
          $1B personal brands <br />
          <span style={{ color: '#fff' }}>for their creators</span>
          <br />
          <br />
          —PassionStocks was built for you.
        </Typography>
      </div>

      <div id="Copy1AB" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          sx={{ color: '#ffbf12', marginBottom: 9 }}
          variant="h3"
        >
          <span style={{ color: '#fff' }}> Whether</span> you’re experienced <br />
          <span style={{ color: '#fff' }}>
            in managing creators <br />
            or
          </span>{' '}
          just starting out<span style={{ color: '#fff' }}>,</span>
          <br />
          <br />
          We’ll teach you how to help creators <br />
          <span style={{ color: '#fff' }}>build high-value personal brands</span> <br />
          with no prior experience needed.
        </Typography>
        <JoinTheWaitlistButton pageType={"agency"}/>
      </div>
    </Container>
  );
}
