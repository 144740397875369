import { Box, Typography } from '@mui/material';
import Agency__OpeningSlide from './OpeningSlide';
import { makeStyles } from '@mui/styles';
import Agency__EstimateSlide from './Estimate';
import Agency__CompanyProcess from './AgencyCompanyProcess';
import Agency__Copy1 from './Copy1';
import Agency__Copy1A from './Copy1A';
import Agency__Copy2 from './Copy2';
import Agency__Copy3 from './Copy3';
import Agency__PSStations from './PSStations';
import Agency__Copy4 from './Copy4';
import Agency__Copy5 from './Copy5';
import JoinTheWaitlistSlide from '../common-slides/JoinTheWaitlist';
import Footer from '../common-slides/Footer';
import PageWithScrollArrows from '../../../components/buttons/PageWithScrollArrows';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
export default function AgencyPage(props) {
  const classes = useStyles();

  return (
    <PageWithScrollArrows id="AgencyPage">
      {/* <div
        id="AgencyPage"
        style={{
          height: '100vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '-webkit-overflow-scrolling': 'touch',
        }}
      > */}
        <Box sx={{ position: 'relative', width: '100vw',margin:0,padding:0 }}>
          <Typography
            className={classes.textOpenSans}
            variant="subtitle1"
            onClick={() => props.setIndex(0)}
            sx={{ position: 'absolute', top: 0, right: 0, padding: 4, cursor: 'pointer' }}
          >
            Creators
          </Typography>
          <Agency__OpeningSlide isPhone={props.isPhone} />
          <Agency__Copy1A />
          <Agency__Copy1 />
          <Agency__EstimateSlide isPhone={props.isPhone} />
          <Agency__Copy2 />
          <Agency__CompanyProcess />
          <Agency__Copy3 />
          <Agency__PSStations />
          <Agency__Copy4 />
          <Agency__Copy5 />
          <JoinTheWaitlistSlide />
          <Footer typePage={'agency'} />
        </Box>
      {/* </div> */}
    </PageWithScrollArrows>
  );
}
