import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy8() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);
  const [marginsD, setmarginsD] = useState(0);

  useEffect(() => {
    const Copy8AElement = document.getElementById('Copy8A');
    const calculatedMarginA = (window.innerHeight - Copy8AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );

    const Copy8BElement = document.getElementById('Copy8B');
    const calculatedMarginB = (window.innerHeight - Copy8BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );

    const Copy8CElement = document.getElementById('Copy8C');
    const calculatedMarginC = (window.innerHeight - Copy8CElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );

    const Copy8DElement = document.getElementById('Copy8D');
    const calculatedMarginD = (window.innerHeight - Copy8DElement.clientHeight) / 2;
    setmarginsD(
      calculatedMarginD > window.innerHeight / 4 ? calculatedMarginD : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy8A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Every creator's
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Personal brand
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 6 }}
        >
          is their startup company{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Prepare your Personal brand <br />
          to be listed on the
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
        >
          Stock exchange
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 6 }}
        >
          Right from day one !
        </Typography>
      </div>

      <div id="Copy8B" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h2"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 4 }}
        >
          Inspire people <br />
          Make a living
          <br />
          Create wealth
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 6 }}
        >
          Just <br />
          By being yourself
        </Typography>
      </div>

      <div id="Copy8C" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          Invite Your Community <br />
          To join{' '}
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 1 }}
        >
          YouR
          <br />
          journey
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          to the Stock Exchange <br />
          and Beyond!
        </Typography>{' '}
      </div>

      <div id="Copy8D" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 4 }}
        >
          Remember
        </Typography>{' '}
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 4 }}
        >
          Today’s <br />
          popularity
          <p style={{ fontSize: '28px', lineHeight: '28px' }}>is</p>
          Tomorrow’s <br />
          Security
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 1 }}
        >
          secure your financial future Today
          <br />
          Better safe than sorry
        </Typography>
      </div>
    </Container>
  );
}
