import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy5() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);
  const [marginsD, setmarginsD] = useState(0);
  const [marginsE, setmarginsE] = useState(0);
  const [marginsF, setmarginsF] = useState(0);
  const [marginsG, setmarginsG] = useState(0);
  const [marginsH, setmarginsH] = useState(0);
  const [marginsI, setmarginsI] = useState(0);

  useEffect(() => {
    const Copy5AElement = document.getElementById('Copy5A');
    const calculatedMarginA = (window.innerHeight - Copy5AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const Copy5BElement = document.getElementById('Copy5B');
    const calculatedMarginB = (window.innerHeight - Copy5BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
    const Copy5CElement = document.getElementById('Copy5C');
    const calculatedMarginC = (window.innerHeight - Copy5CElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );
    const Copy5DElement = document.getElementById('Copy5D');
    const calculatedMarginD = (window.innerHeight - Copy5DElement.clientHeight) / 2;
    setmarginsD(
      calculatedMarginD > window.innerHeight / 4 ? calculatedMarginD : window.innerHeight / 4,
    );
    const Copy5EElement = document.getElementById('Copy5E');
    const calculatedMarginE = (window.innerHeight - Copy5EElement.clientHeight) / 2;
    setmarginsE(
      calculatedMarginE > window.innerHeight / 4 ? calculatedMarginE : window.innerHeight / 4,
    );
    const Copy5FElement = document.getElementById('Copy5F');
    const calculatedMarginF = (window.innerHeight - Copy5FElement.clientHeight) / 2;
    setmarginsF(
      calculatedMarginF > window.innerHeight / 4 ? calculatedMarginF : window.innerHeight / 4,
    );
    const Copy5GElement = document.getElementById('Copy5G');
    const calculatedMarginG = (window.innerHeight - Copy5GElement.clientHeight) / 2;
    setmarginsG(
      calculatedMarginG > window.innerHeight / 4 ? calculatedMarginG : window.innerHeight / 4,
    );
    const Copy5HElement = document.getElementById('Copy5H');
    const calculatedMarginH = (window.innerHeight - Copy5HElement.clientHeight) / 2;
    setmarginsH(
      calculatedMarginH > window.innerHeight / 4 ? calculatedMarginH : window.innerHeight / 4,
    );
    const Copy5IElement = document.getElementById('Copy5I');
    const calculatedMarginI = (window.innerHeight - Copy5IElement.clientHeight) / 2;
    setmarginsI(
      calculatedMarginI > window.innerHeight / 4 ? calculatedMarginI : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{}}>
      <div id="Copy5A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 0 }}
        >
          So, What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Your Community?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 0 }}
        >
          They get
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 3 }}
        >
          Story Shoutouts{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 0 }}
        >
          & the <span style={{ color: '#ffbf12' }}>opportunity</span> to become{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 0 }}
        >
          Business Partners
          <br />
          of your personal brand
        </Typography>
      </div>

      <div id="Copy5B" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Wondering how to let{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Your Community become <br />
          business partners <br />
          of your personal brand?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          You have the <span style={{ color: '#ffbf12' }}>option</span> to <br />
          allow <span style={{ color: '#ffbf12' }}>certain buyers</span> to{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          resell your shoutouts
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          on your behalf at higher prices, <br />
          giving them the opportunity <br />
          to act as resellers.{' '}
        </Typography>
      </div>
      <div id="Copy5C" style={{ margin: `${marginsC}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Wondering how to
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          optimize your <br />
          sales & resales?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          By Controlling{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          shoutout <br />
          Supply & demand{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 1 }}
        >
          ,to drive value growth <br />- for you & your partners.{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          & Including{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Packages & Perks
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          with your shoutout sales, <br />
          offering them based <br />
          on the quantity purchased.{' '}
        </Typography>
      </div>

      <div id="Copy5D" style={{ margin: `${marginsD}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Businesses & brands?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          They get{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          exclusive story
          <br />
          shoutout
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          you can also offer them{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Business Packages{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 3 }}
        >
          with your shoutout sales, based <br />
          on the quantity purchased.
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          you can also offer them{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Real-time marketing
          <br />
          In a preset format{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 3 }}
        >
          <span style={{ color: '#ffbf12' }}>just pay & promote.</span>
          <br />
          Turning <br />
          <span style={{ color: '#ffbf12' }}>moments into momentum </span>
          <br />
          for your brand and theirs.
        </Typography>
      </div>

      <div id="Copy5E" style={{ margin: `${marginsE}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          PassionStocks?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          PassionStocks takes{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          up to 20%
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          of Story shoutouts
          <br />
          sales revenue.{' '}
        </Typography>
      </div>

      <div id="Copy5F" style={{ margin: `${marginsF}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Still not sure{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          how to come up with <br />
          a strategy <br />
          for scalable sales?{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Join the{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 10 }}
          variant="h2"
        >
          Passionstocks <br />
          Academy{' '}
        </Typography>
        <JoinTheWaitlistButton />{' '}
      </div>

      <div id="Copy5G" style={{ margin: `${marginsG}px auto` }}>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          don’t have time <br />
          to manage your <br />
          personal brand as
          <br />A startup?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          get a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 2 }}
          variant="h2"
        >
          Personal brand
          <br />
          Manager{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          <span style={{ color: '#ffbf12' }}> Invite your own manager</span> or <br />
          <span style={{ color: '#ffbf12' }}> find a manager on PassionStocks</span>.
        </Typography>
      </div>

      <div id="Copy5H" style={{ margin: `${marginsH}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Personal brand
          <br />
          Manager?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          They can set a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Management fee{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          from the shoutout sales revenue.
        </Typography>
      </div>

      <div id="Copy5I" style={{ margin: `${marginsI}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Still Wondering how{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          PassionStocks helps
          <br />
          To secure
          <br />
          your financial future?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          PassionStocks helps to turn <br />
          your personal brand into <br />
          a tangible asset by focusing on <br />
          one scalable income stream:
          <br />
          selling shoutouts.{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 10 }}
          variant="h2"
        >
          This asset grows <br />
          with your activities <br />
          and can be insured <br />
          for long-term <br />
          Financial security.{' '}
        </Typography>
        <JoinTheWaitlistButton />
        <br/><br/><br/>
        <Container sx={{width:'250px'}}>
        <HorizontalLine /></Container>
      </div>
    </Container>
  );
}
