import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy3() {
  const classes = useStyles();

  const [margins, setmargins] = useState(0);
  useEffect(() => {
    const copy1aElement = document.getElementById('Copy3A');
    const calculatedMarginA = (window.innerHeight - copy1aElement.clientHeight) / 2;
    setmargins(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container
      sx={{
      
      }}
    >
      <div id="Copy3A" style={{ margin: `${margins}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1, marginBottom: 1 }}
        >
          Still Wondering how{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 4 }}
          variant="h2"
        >
          Limited automated
          <br />
          Thank-you Mention <br />
          cards
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1, marginBottom: 1 }}
        >
          can help you build{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 0 }}
        >
          $1B personal brand?{' '}
        </Typography>
      </div>
    </Container>
  );
}
