import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProcessSteps from '../../../../components/ProcessSteps';
import { useState } from 'react';
import CommunityImpact__ExclusiveShoutOut from './CommunityImpact__ExclusiveShoutOut';
import CommunityImpact__EarningFromGrowth from './CommunityImpact__EarningFromGrowth';
import CommunityImpact__ExclusiveGiveaways from './CommunityImpact__ExclusiveGiveaways';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
   
  },
}));

export default function CommunityImpact__mobile(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        position: 'absolute',
        color: index === 0 ? 'white' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '25px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(0);
      }}
    >
      Exclusive <br />
      Shout-out
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        position: 'absolute',
        color: index === 1 ? 'white' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '25px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(1);
      }}
    >
      Earning from <br />
      Your growth
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        position: 'absolute',
        color: index === 2 ? 'white' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '25px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(2);
      }}
    >
      Exclusive <br />
      Giveaways
    </Typography>,
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: 'max-content',
        width: '100vw',
        padding: 0,
        margin: 0,
        marginBottom: 5,
      }}
    >
      <div style={{ zIndex: 5, margin: ' 0', width: 'fit-content' }}>
        <ProcessSteps
          index={index}
          setIndex={setIndex}
          length={stepsNames.length}
          stepsNames={stepsNames}
        />
      </div>

      <Box sx={{ width: 'fill-available' }}>
        {index === 0 && <CommunityImpact__ExclusiveShoutOut fontStyle={classes.textMonarcha} />}
        {index === 1 && <CommunityImpact__EarningFromGrowth fontStyle={classes.textMonarcha} />}
        {index === 2 && <CommunityImpact__ExclusiveGiveaways fontStyle={classes.textMonarcha} />}
      </Box>
    </Box>
  );
}
