import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy2() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);

  useEffect(() => {
    const Copy2AElement = document.getElementById('Copy2A');
    const calculatedMarginA = (window.innerHeight - Copy2AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const Copy2BElement = document.getElementById('Copy2B');
    const calculatedMarginB = (window.innerHeight - Copy2BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
    const Copy2CElement = document.getElementById('Copy2C');
    const calculatedMarginC = (window.innerHeight - Copy2CElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy2A" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.6 }}
        >
          Wondering{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          How does <br />
          our One-product <br />
          marketplace
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.6, marginBottom: 3 }}
        >
          For shoutouts{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 0 }}
        >
          Add tangible value To <br />
          your personal brand?
        </Typography>
      </div>

      <div id="Copy2B" style={{ margin: `${marginsB}px auto` }}>
        {' '}
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          Since
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ marginBottom: 1 }}
          variant="h2"
        >
          Each Shoutout’s Value
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          On our marketplace comes
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 1 }}
        >
          Entirely
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          From the
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 1 }}
        >
          Personal brand
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 10 }}
        >
          - every sale adds <br />
          tangible value to that b{' '}
        </Typography>
      </div>

      <div id="Copy2C" style={{ margin: `${marginsC}px auto` }}>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          our marketplace is where
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          Every creator
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          Sells the
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1 }}
        >
          Same <br />
          exact product
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          Under the{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 7 }}
        >
          Same
          <br /> market conditions{' '}
        </Typography>

        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          the
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          different <br />
          sales volumes{' '}
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          show each
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          personal brand’s <br />
          unique value.
        </Typography>
      </div>
    </Container>
  );
}
