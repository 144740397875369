import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TYMentionEx1 from '../../../../../../../images/ty-mention-ex1.png';
import TYMentionEx2 from '../../../../../../../images/ty-mention-ex2.png';
import TYMentionEx3 from '../../../../../../../images/ty-mention-ex3.png';
import ImageGallery from '../../../../../components/ImageGallery';
import SwipeableViews from 'react-swipeable-views';
import { useEffect, useRef, useState } from 'react';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';

export default function OneProduct__TextAndTagging(props) {
  const refText = useRef(null);
  const [widthText, setwidthText] = useState(0);
  useEffect(() => {
    if (refText.current) {
      setwidthText(refText.current.offsetWidth);
    }
  }, []);

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
    console.log(i);
  };

  // ImageGallery without thumbnails - should maintain 'open' state on the parent component
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '62vw', paddingTop: 6 }}>
      <Typography
        className={props.fontStyle}
        ref={refText}
        sx={{
          display: 'block',
          color: '#ffffff44',
          width: 'fit-content',
          lineHeight: 1.2,
          fontSize: '11px !important',
          textAlign: 'center',
          marginBottom: 5,
        }}
      >
       The <span style={{ color: '#ffbf12' }}>buyer</span> gets a{' '}
          <span style={{ color: '#ffbf12' }}>shoutout</span><br/> with{' '}
          <span style={{ color: '#ffbf12' }}>just</span> their <span style={{ color: '#ffbf12' }}>Instagram handle</span>.<br/>
          <br />
          It’s a <span style={{ color: '#ffbf12' }}>fixed templatE — text & tagging only</span><br/> with no option to add text or images, <br />
          and the <span style={{ color: '#ffbf12' }}>delivery is fully automated</span><br/><br/> <span style={{ color: '#ffbf12' }}>Below, you can see examples of delivered Shoutouts.</span>
           </Typography>
    
      <SwipeableViews style={{ width: '100%' }} index={index} onChangeIndex={handleChangeIndex}>
        <div
          style={{
            width: `${widthText}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={TYMentionEx1}
            style={{ width: '57.25vw', display: 'block', margin: '0 auto' }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        <div
          style={{
            width: `${widthText}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={TYMentionEx2}
            style={{ width: '46vw', display: 'block', margin: '0 auto' }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        <div
          style={{
            width: `${widthText}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={TYMentionEx3}
            style={{ width: '53vw', display: 'block', margin: '0 auto' }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </SwipeableViews>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '9vw',
          opacity: 0.44,
          cursor: 'pointer',
          margin: '25px auto 0',
        }}
        onClick={() => {
          setIndex((index + 1) % 3);
        }}
      >
        <img
          src={DoubleArrows}
          alt="Click to view card details"
          style={{ width: '18px', transform: 'rotate(-90deg)' }}
        />
        <Typography
          className={props.fontStyle}
          sx={{ fontSize: '12px', textAlign: 'center', lineHeight: 1.2, marginTop: 0.5 }}
        >
          {index + 1}/3
        </Typography>
      </div>
      {/* </div>{' '} */}

      <ImageGallery
        images={[TYMentionEx1, TYMentionEx2, TYMentionEx3]}
        open={open}
        setOpen={setOpen}
        index={index}
      />
    </Box>
  );
}
