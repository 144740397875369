import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../layouts/fade-in';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

export default function ScalabilityDrives__Scalability(props) {
  const classes = useStyles();
  const textRef = useRef(null);

  const [graphImgHeight, setgraphImgHeight] = useState(0);
  useEffect(() => {
    if (textRef.current) {
      setgraphImgHeight(textRef.current.offsetHeight * 0.9);
    }
  }, [textRef]);

  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            position: 'relative',
            width: 'fit-content',
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginRight: 'auto !important',
            marginLeft: 'auto !important',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#ffbf12' }}>
            {' '}
            Shoutout sales on passionstocks are scalable because <br />
          </span>
          they’re <span style={{ color: '#ffbf12' }}>digital</span>,{' '}
          <span style={{ color: '#ffbf12' }}>automated</span>,{' '}
          <span style={{ color: '#ffbf12' }}>templated</span>, and easily
          <span style={{ color: '#ffbf12' }}> consumable</span>.
          <br />
          <br />
          allowing for <span style={{ color: '#ffbf12' }}>
            easy replication & distribution{' '}
          </span>{' '}
          <br />
          without additional effort.
          <img
            src={'/images/scalability1.svg'}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
              transform: 'translate(120%,0)',
            }}
          />
        </Typography>
        {/* </div> */}
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          Shoutouts are a one-time consumable digital service, <br />
          simplifying transactions by reducing* the legal and accounting complexities <br />
          of physical goods, ongoing commitments, or crypto coins & assets.{' '}
        </Typography>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={'/images/scalability2.svg'} style={{ height: graphImgHeight }} />
          <Typography
            ref={textRef}
            className={props.fontStyle}
            variant="subtitle1"
            sx={{
              color: '#ffffff44',
              lineHeight: 1.3,
              textAlign: 'center',
              margin: 0,
              marginLeft: 1,
            }}
          >
            By <span style={{ color: '#ffbf12' }}>default</span>, shoutouts are{' '}
            <span style={{ color: '#ffbf12' }}>capped</span> at 1% of your total followers at a
            time, <br />
            with a maximum of 250 million to{' '}
            <span style={{ color: '#ffbf12' }}>optimize supply & demand</span>.
            <br />
            <br />
            <span style={{ color: '#ffbf12' }}>You</span> can{' '}
            <span style={{ color: '#ffbf12' }}>adjust</span> this{' '}
            <span style={{ color: '#ffbf12' }}>limit</span> based on{' '}
            <span style={{ color: '#ffbf12' }}>your sales strategy</span>.
          </Typography>
        </div>
        <br />

        <Typography
          className={classes.textOpenSans}
          variant="subtitle1"
          sx={{
            position: 'relative',
            display: 'block',
            fontSize: '12px',
            color: '#ffffff44',
            lineHeight: 1.3,
            width: '72%',
            marginRight: 'auto !important',
            marginLeft: 'auto !important',
          }}
        >
          *PassionStocks does not provide legal, financial, or accounting advice. Always consult a
          professional or use PassionStocks’ recommended third-party services for trademarks,
          contracts, or other legal and financial matters.
          <br />
          These recommendations are not endorsements but suggestions for your convenience.
        </Typography>
      </Container>
    </FadeIn>
  );
}
